&.reservation_page, &.schoolreis_page {

	.bundle-overview-section {
		border-width: 165px;

		@include media-breakpoint-down(xs) {
			border-width: 130px;
		}

		.container-holder {
			margin-top: -165px;
		}
	}

	// calculator
	.calculator, .schoolreis_calculator {

		// options
		.options {
			padding-bottom: 15px;

			&:has(.calculator),&:has(.schoolreis_calculator) {
				max-width: 720px;
				margin: auto;
			}

			.heading {
				color: $black;
				margin-bottom: 1rem;
			}

			.calculator,.schoolreis_calculator {
				background-color: $primary-dark;
				padding: 30px;
				border-radius: 30px;
				font-size: 16px;
				position: relative;
				z-index: 101;

				a:hover {
					color: $secondary;
				}

				.alert {
					&.alert-danger {
						background: $white;
						border-radius: 7px;
					}

					&.alert-warning {
						color: #856404;
						background-color: #fff3cd;
						border-color: #ffeeba;

						p {
							color: #856404;
							margin: 0;
						}
					}

					&.alert-success {
						color: #566f28;
						background-color: #edf7db;
						border-color: #e6f3cd;
					}
				}

				.form-control {
					border-radius: 7px;
				}


			}

			.items {
				.item {
					display: flex;
					align-items: center;
					margin-bottom: 15px;

					@include media-breakpoint-down(sm) {
						flex-direction: column;
						align-items: flex-start;
					}

					.amount {
						display: flex;
						align-items: center;
						min-width: 220px;

						.minus,
						.plus {
							width: 36px;
							height: 36px;
							color: #c9c9c9;
							line-height: 36px;
							text-align: center;
							transition: color 0.5s;
							cursor: pointer;

							&:hover {
								color: $black;
							}
						}

						.number {
							width: 110px;
							height: 36px;
							margin: 0 5px;
							border-color: $white;
							border-radius: 4px;
							box-shadow: 0 0 25px rgba($black, 0.15);
							color: #c9c9c9;
							text-align: center;

							&.above-zero {
								color: #243b68;
							}
						}
					}
				}
			}

			.pp-costs {
				display: flex;
				flex-flow: row nowrap;
				font-size: 24px;
				margin-top: 45px;

				.text {
					flex-grow: 1;
					margin-right: 15px;
					font-weight: 400;
				}

				.price {
					font-weight: 700;
					white-space: nowrap;
				}
			}
		}

		// items
		.items {
			.col-12 {
				@include media-breakpoint-down(xs) {
					margin-bottom: 20px;
				}
			}

			.reservation_type {
				cursor: pointer;
				border-radius: 30px;
				border: 1px solid $white;
				.card-body {
					padding: 25px 1.5rem 10px;
					z-index: 101;

					.card-title {
						position: relative;
						font-size: 26px;
						font-weight: 400;

						.price {
							position: absolute;
							background: $white;
							padding: 8px 16px;
							border-radius: 50px;
							color: $primary-dark;
							right: 0;
							top: -50px;
							font-weight: 400;
							font-size: 22px;
						}
					}

					.js-pick-button {
						background-color: transparent;
						border: 1px solid $white;
						margin: 1.5rem auto;
						width: 95%;
						display: block;
						font-size: 24px;
						font-weight: 300;
						font-family: "Montagu Slab", serif;
						color: $white;
						padding: 1rem;
						border-radius: 50px;
					}
				}
				&.active {
					background: $primary-dark;
					border: 1px solid $primary-dark;

					.card-image {
						&::before {
							background: linear-gradient(180deg, rgba($primary-dark, 0) 0%, rgba($primary-dark, 1) 100%);
						}
					}

					.js-pick-button {
						background-color: $white;
						color: $primary-dark;
					}
				}
			}
		}

		// extra-description
		.extra-description {
			background-color: $white;
			border-radius: 30px;
			border: 1px solid $white;
			padding: 1.5rem 5rem;
			margin-top: 35px;
			@include media-breakpoint-down(xs) {
				padding: 1.5rem;
				margin-top: 15px;
			}
			ul {
				list-style: none;
				padding-left: 1rem;
				li {
					color: $black;
					position: relative;
					&::before {
						position: absolute;
						left: -1rem;
						top: -5px;
						content: "»";
						color: $black;
						font-size: 18px;
					}
				}
			}
			p {
				color: $black;
			}

			a {
				&:hover {
					color: $primary-dark;
				}
			}

			h1, h2, h3, h4, h5, h6 {
				color: $primary-dark;
				font-size: 20px;
				font-weight: 400;
			}
		}

		// extras
		.extras {
			border: 1px solid $white;
			border-radius: 30px;
			padding: 1.5rem;
			margin-top: 35px;

			.heading {
				margin-bottom: 20px;
				font-weight: 400;
			}

			.extra-row {
				display: flex;
				flex-flow: row nowrap;
				margin-top: 15px;
				padding-top: 15px;
				justify-content: space-between;
				font-size: 24px;

				&:not(:first-of-type) {
					border-top: 1px solid $white;
				}

				&.extra-footer {
					flex-flow: column nowrap;
					align-items: flex-end;
				}

				.items {
					@extend .w-100;

					.item {
						display: flex;
						font-size: 0.875rem;
						justify-content: space-between;

						&:not(:last-of-type) {
							margin-bottom: 15px;
						}

						.custom-checkbox {
							margin-right: 15px;

							.custom-control-input {
								&:checked {
									~.custom-control-label {
										&::after {
											background-color: $primary-dark;
											border-color: $primary-dark;
											border-radius: 0.25rem;
										}
									}

								}
							}

							.info {
								margin: 0 5px;
								transition: color 0.5s;
								cursor: pointer;
							}
						}

						.price {
							font-weight: 500;
							white-space: nowrap;
						}
					}
				}

				.title {
					font-weight: 400;
				}

				.content {
					font-weight: 700;

					input[type=number] {
						font-size: 0.875rem;
						max-width: 60px;
						border: unset;
						padding: 0 0 0 10px;
						border-radius: 5px;

						&:focus-visible {
							outline: none;
						}
					}
				}

				.btn-wrapper {
					.disabled {
						background: lighten($blue, 50%);
						border-color: lighten($blue, 50%);
						cursor: not-allowed;
					}
				}
			}


			.mail_reservation {
				text-align: right;

				.btn {
					margin-top: 30px;
				}
			}
		}
	}

	// footer-info
	.footer-info {
		.more-info {
			display: block !important;

			@include media-breakpoint-up(xl) {
				margin-left: 45px;
			}
		}

	}

}
