// menu-overlay
.menu-overlay {
    z-index: 997;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    background-color: rgba($white, 1);
    transition: 0.5s;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
        overflow-y: auto;
    }
    &.show {
        height: 100vh;
        bottom: 0;
    }

    // menu-overlay-top
    .menu-overlay-top {
        border-radius: 10px;
        background: $primary-dark;
        @include media-breakpoint-down(lg) {
            min-height: calc(75% - 40px);
            margin: 10px 10px 40px 10px;
            padding: 80px 30px;
        }
        @include media-breakpoint-up(xl) {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: calc(75% - 60px);
            margin: 20px 20px 40px 20px;
        }
        
        // menu
        .menu {
            display: flex;
            @include media-breakpoint-up(xl) {
                justify-content: center;
            }
            @include media-breakpoint-down(lg) {
                .navbar-nav {
                    width: 100%;
                    > .nav-item {
                        > .nav-link {
                            color: $white;
                            font-size: 20px;
                            font-weight: 300;
                            font-family: $font-family-secondary;
                            border-bottom: 1px solid $primary;
                            &:hover {
                                color: unset;
                            }
                        }
                        &.show {
                            > .nav-link {
                                border-color: $primary-dark;
                            }
                        }
                    }
                    .nav-item {
                        .nav-link {
                            padding: 0;
                            color: $white;
                            &:hover {
                                color: $primary;
                            }
                            &.dropdown-toggle {
                                &::after {
                                    content: "\f078";
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    color: $primary;
                                    font-size: 18px;
                                    line-height: 37px;
                                    font-family: $font-awesome;
                                    border: none;
                                    margin-left: 0;
                                }
                            }
                        }
                        .dropdown-menu {
                            position: static !important;
                            transform: none !important;
                            margin: 0 0 0 20px;
                            padding: 0;
                            border: none;
                            background-color: transparent;
                        }
                    }
                }
            }
            @include media-breakpoint-up(xl) {
                .navbar-nav {
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: center;
                    > .nav-item {
                        margin: 30px;
                        text-align: center;
                        &:first-of-type {
                            display: none;
                        }
                        > .nav-link {
                            color: $white;
                            font-size: 24px;
                            font-weight: 300;
                            font-family: $font-family-secondary;
                            border-bottom: 1px solid $primary;
                            pointer-events: none;
                            &::after {
                                display: none;
                            }
                            &:hover {
                                color: unset;
                            }
                        }
                    }
                    .nav-item {
                        .nav-link {
                            padding: 0;
                            color: $white;
                            &:hover {
                                color: $primary;
                            }
                        }
                        .dropdown-menu {
                            position: static !important;
                            transform: none !important;
                            display: block;
                            margin: 10px 0 0 0;
                            padding: 0;
                            border: none;
                            background-color: transparent;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    // menu-overlay-bottom
    .menu-overlay-bottom {
        position: relative;
        min-height: 25vh;
        padding: 0 25px;
        &::after {
            content: "";
            background: url("/images/menu-overlay-curl-blue-dark.svg");
            z-index: -1;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 30.5vh;
            height: 25vh;
            @include media-breakpoint-down(sm) {
                width: 160px;
                height: 131px;
            }
        }
        .container-holder {
            max-width: 1140px;
            margin: 0 auto;
        }
        @include media-breakpoint-down(md) {
            .column {
                &.one,
                &.two {
                    display: none;
                }
                &.three,
                &.four {
                    @include make-col(12);
                }
                h4 {
                    display: none;
                }
            }
        }
        .column {
            &.three {
                color: $primary-dark;
            }
        }
        h4 {
            margin-bottom: 10px;
        }
        a {
            color: $primary-dark;
            font-weight: 700;
            text-decoration: none;
            &:hover {
                color: $primary;
            }
        }
        .footer_text {
            p:last-of-type {
                margin-bottom: 0;
            }
        }
        .footer_logolink {
            .list {
                .list-item {
                    .link {
                        display: block;
                        width: 42px;
                        height: 42px;
                        border-radius: 100%;
                        background-color: $primary-dark;
                        color: $white;
                        font-size: 24px;
                        line-height: 42px;
                        text-align: center;
                        i {
                            line-height: 42px;
                        }
                        &:hover {
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }

}
