// bg-dark
section.bg-dark {
    margin: 0;
    padding: 6vh 0;
}

// usp-section
.usp-section {
    margin: 4vh 0;
    ol {
		justify-content: space-around;
    }
}

// lead-section
.lead-section {
    margin: 6vh 0;
    .container-holder {
        p {
            max-width: 750px;
        }
    }
    /* bundle */
    .info-nav {
        @extend .d-flex;
        @extend .flex-wrap;
        @extend .align-items-center;
        @extend .justify-content-between;
        margin-bottom: 30px;
    }
    .info {
        // accommodation
        .properties {
            @extend .d-flex;
            @extend .flex-wrap;
            @extend .list-unstyled;
            li + li {
                margin-left: 15px;
            }
            .icon {
                margin-right: 5px;
            }
        }
    }
}

// content-section
.content-section {
    margin: 6vh 0;
    .column.one.wide,
    .column.two.wide {
        @include media-breakpoint-up(xl) {
            @include make-col(8);
        }
    }
    .column.one.narrow,
    .column.two.narrow {
        @include media-breakpoint-up(xl) {
            @include make-col(4);
        }
    }
    &.bg-dark {
        position: relative;
        @include media-breakpoint-down(lg) {
            margin-top: 115px;
        }
        &::before {
            content: "";
            z-index: 100;
            position: absolute;
            left: 0;
            bottom: 100%;
            width: 89px;
            height: 150px;
            margin-bottom: -45px;
            background: url("/images/visual-curl-half-blue-dark.svg");
			background-size: 100%;
			@include media-breakpoint-down(sm) {
				width: 67px;
				height: 112px;
			}
        }
        &::after {
            content: "";
            z-index: 100;
            position: absolute;
            right: 0;
            bottom: 15px;
            width: 82px;
            height: 150px;
            background: url("/images/visual-curl-half-right-white.svg");
			background-size: 100%;
			@include media-breakpoint-down(sm) {
				width: 41px;
				height: 75px;
			}
        }
        .card {
            .card-image {
                &::before {
                    background: linear-gradient(180deg, rgba($primary-dark, 0) 0%, rgba($primary-dark, 1) 100%);
                }
            }
        }
    }
}

// contentblock-section
.contentblock-section {
    position: relative;
    margin: 6vh 0;
    padding: 0 0 8vh 0;
    border-top: 80px solid $white;
    &::before {
        content: "";
        z-index: 100;
        position: absolute;
        left: 0;
        bottom: 100%;
        width: 89px;
        height: 150px;
        margin-bottom: -45px;
        background: url("/images/visual-curl-half-blue.svg");
		background-size: 100%;
		@include media-breakpoint-down(sm) {
			width: 67px;
			height: 112px;
		}
    }
    &::after {
        content: "";
        z-index: 100;
        position: absolute;
        right: 0;
        bottom: 15px;
        width: 82px;
        height: 150px;
        background: url("/images/visual-curl-half-right-white.svg");
		background-size: 100%;
		@include media-breakpoint-down(sm) {
			width: 41px;
			height: 75px;
        }
    }
    .container-holder {
        margin-top: -80px;
    }
    &.bg-blue-dark-speeltuin {
        &::before {
            background: url("/images/visual-curl-half-blue-dark.svg") !important;
        }
    }
}
&.home {
    .contentblock-section {
        margin-top: 4vh;
    }
}

// highlight-section
.highlight-section {
    position: relative;
    margin: 8vh 0;
    padding: 0 0 6vh 0;
    border-top: 80px solid $white;
    .column.one.wide {
        @include media-breakpoint-down(sm) {
            order: 1;
        }
    }
    .column.one.wide,
    .column.two.wide {
        @include media-breakpoint-up(xl) {
            @include make-col(7);
        }
    }
    .column.one.narrow,
    .column.two.narrow {
        z-index: 2;
        @include media-breakpoint-up(xl) {
            @include make-col(5);
        }
        padding-top: 6vh;
        @include media-breakpoint-up(md) {
            padding-top: 80px;
        }
    }
    .resource_image {
        position: relative;
        @include media-breakpoint-up(md) {
            margin-top: -80px;
        }
        border-radius: 10px;
        overflow: hidden;
        &::before {
            content: "";
            z-index: 100;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 80px;
        }
    }
    ol.custom-list {
        margin-top: 30px;
		justify-content: space-around;
		li {
			@include media-breakpoint-down(sm) {
				width: 48%;
			}
		}
    }

    // custom
    &.bg-blue-dark {
        @include media-breakpoint-up(md) {
            margin-top: 200px;
        }
        &::before {
            content: "";
            z-index: 1;
            position: absolute;
            bottom: 100%;
            @include media-breakpoint-down(md) {
                right: 0;
                width: 131px;
                height: 112px;
                background: url("/images/visual-tree-half-blue-dark.svg");
				background-size: 100%;
            }
            @include media-breakpoint-up(lg) {
                right: 25%;
                width: 397px;
                height: 221px;
                background: url("/images/visual-tree-blue-dark.svg");
				background-size: 100%;
            }
        }
        .resource_image {
            &::before {
                background: linear-gradient(180deg, rgba($primary-dark, 0) 0%, rgba($primary-dark, 1) 100%);
            }
        }
        ol.custom-list {
            li {
                min-height: 82px;
                padding-left: 35px;
                &::before {
                    width: 68px;
                    height: 82px;
                    margin-left: -35px;
                    background: url("/images/visual-splash-large-blue.svg");
                }
            }
        }
    }
    &.bg-green-dark {
        @include media-breakpoint-up(md) {
            margin-top: 200px;
        }
        &::before {
            content: "";
            z-index: 1;
            position: absolute;
            bottom: 100%;
            @include media-breakpoint-down(sm) {
                right: 0;
                width: 129px;
                height: 113px;
                background: url("/images/visual-curl-half-green-dark.svg");
				background-size: 100%;
            }
            @include media-breakpoint-up(md) {
                right: 50%;
                width: 300px;
                height: 300px;
                margin-bottom: -125px;
                background: url("/images/visual-curl-round-green-dark.svg");
				background-size: 100%;
            }
        }
        .resource_image {
            &::before {
                background: linear-gradient(180deg, rgba($secondary-dark, 0) 0%, rgba($secondary-dark, 1) 100%);
            }
        }
        ol.custom-list {
            li {
                min-height: 82px;
                padding-left: 35px;
                &::before {
                    width: 68px;
                    height: 82px;
                    margin-left: -35px;
                    background: url("/images/visual-splash-large-green.svg");
                }
            }
        }
    }
    &.bg-yellow {
        @include media-breakpoint-up(md) {
            margin-top: 200px;
        }
        &::before {
            content: "";
            z-index: 1;
            position: absolute;
            bottom: 100%;
            @include media-breakpoint-down(sm) {
                right: 0;
                width: 129px;
                height: 113px;
                background: url("/images/visual-curl-half-yellow.svg");
				background-size: 100%;
            }
            @include media-breakpoint-up(md) {
                right: 50%;
                width: 300px;
                height: 300px;
                margin-bottom: -125px;
                background: url("/images/visual-curl-round-yellow.svg");
				background-size: 100%;
            }
        }
        .resource_image {
            &::before {
                background: linear-gradient(180deg, rgba($secondary-dark, 0) 0%, rgba($secondary-dark, 1) 100%);
            }
        }
        ol.custom-list {
            li {
                min-height: 82px;
                padding-left: 35px;
                color: black !important;
                text-decoration: none;
                &::before {
                    width: 68px;
                    height: 82px;
                    margin-left: -35px;
                    background: url("/images/visual-splash-large-yellow.svg");
                }
            }
            a {
                color:black !important;
            }
        }
    }
}

// news-section
.news-section {
    margin: 6vh 0;
    .page_block.grid {
        .item {
            @include make-col(12);
            .card {
                @extend .card-wide;
				@include media-breakpoint-down(sm) {
					justify-content: center;
				}
                .card-image {
                    @include make-col(6);
                    @include media-breakpoint-up(sm) {
                        @include make-col(6);
                    }
                    @include media-breakpoint-up(md) {
                        @include make-col(4);
                    }
                    @include media-breakpoint-up(lg) {
                        @include make-col(3);
                    }
                    @include media-breakpoint-up(xl) {
                        @include make-col(3);
                    }
                }
                .card-body {
                    @include make-col(12);
                    @include media-breakpoint-up(sm) {
                        @include make-col(12);
                    }
                    @include media-breakpoint-up(md) {
                        @include make-col(8);
                    }
                    @include media-breakpoint-up(lg) {
                        @include make-col(6);
                    }
                    @include media-breakpoint-up(xl) {
                        @include make-col(6);
                    }
                }
                .card-image {
                    border-radius: 100%;
                    overflow: hidden;
                    &::before {
                        display: none;
                    }
                }
                .card-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
					@include media-breakpoint-down(sm) {
						text-align: center;
					}
                    @include media-breakpoint-up(md) {
                        padding: 0 30px;
                    }
                    .card-caption {
                        flex: none;
                    }
                    .card-buttons {
                        .card-btn {
                            @extend .btn-primary;
                        }
                    }
                }
            }
            &:nth-child(even) {
                @include media-breakpoint-up(md) {
                    .card {
                        justify-content: flex-end;
                        .card-image {
                            order: 1;
                        }
                        .card-body {
                            order: 0;
                        }
                    }
                }
            }
        }
    }
}

// bundle-overview-section
.bundle-overview-section {
    position: relative;
    margin: 6vh 0;
    padding: 0 0 6vh 0;
    border-top: 80px solid $white;
    @extend .bg-blue;
    &::before {
        content: "";
        z-index: 100;
        position: absolute;
        left: 0;
        bottom: 100%;
        width: 89px;
		height: 150px;
        margin-bottom: -45px;
        background: url("/images/visual-curl-half-blue.svg");
		background-size: 100%;
		@include media-breakpoint-down(sm) {
			width: 67px;
			height: 112px;
        }
    }
    &::after {
        content: "";
        z-index: 100;
        position: absolute;
        right: 0;
        bottom: 15px;
        width: 82px;
        height: 150px;
        background: url("/images/visual-curl-half-right-white.svg");
		background-size: 100%;
		@include media-breakpoint-down(sm) {
			width: 41px;
			height: 75px;
		}
    }
    .container-holder {
        margin-top: -80px;
    }
    .grid {
        .item {
            @include media-breakpoint-up(xl) {
                margin-bottom: 6vh !important;
            }
        }
    }
    .blog_filter {
        @extend .d-none;
    }
}

&.accommodation_arrangement_overview,
&.accommodation_search_book {
    .bundle-overview-section {
        @include media-breakpoint-down(md) {
            border-top: 0;
            &::before {
               display: none;
            }
        }
        @include media-breakpoint-up(lg) {
            border-top: 265px solid $white;
        }
        .container-holder {
            @include media-breakpoint-down(md) {
                margin-top: 0;
            }
            @include media-breakpoint-up(lg) {
                margin-top: -265px;
            }
        }
    }
}

// bundle-detail-section
.bundle-detail-section {
    position: relative;
    margin: 6vh 0;
    padding: 0 0 6vh 0;
    @include media-breakpoint-down(md) {
        margin-top: 150px;
    }
    @include media-breakpoint-up(lg) {
        border-top: 80px solid $white;
    }
    @extend .bg-blue-dark;
    .column.one {
		@include media-breakpoint-down(sm) {
			order: 1;
		}
        padding-top: 6vh;
        @include media-breakpoint-up(lg) {
            padding-top: 80px;
        }
    }
	.column.two {
		@include media-breakpoint-down(sm) {
			order: 0;
		}
    }
    .gallery {
        position: relative;
		margin-top: -40px;
        @include media-breakpoint-up(lg) {
            margin-top: -80px;
        }
    }
    &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 100%;
        width: 175px;
        height: 150px;
        @include media-breakpoint-up(xl) {
			z-index: 100;
            width: 255px;
            height: 218px;
        }
        background: url("/images/visual-tree-half-blue-dark.svg");
        background-size: cover;
    }
    &::after {
        content: "";
        z-index: 100;
        position: absolute;
        left: 0;
        bottom: 15px;
        width: 66px;
        height: 75px;
        @include media-breakpoint-up(xl) {
            width: 131px;
            height: 150px;
            bottom: 30px;

        }
        background: url("/images/visual-leaves-white.svg");
    }
}

// gallery-section
.gallery-section {
    position: relative;
    border-top: 80px solid $white;
    @extend .bg-blue-dark;
    &::before {
        content: "";
        z-index: 100;
        position: absolute;
        left: 0;
        bottom: 100%;
        width: 101px;
        height: 171px;
        margin-bottom: -45px;
        background: url("/images/visual-curl-half-blue-dark.svg");
    }
    .container {
        @extend .container-fluid;
        @include media-breakpoint-up(xl) {
            padding: 0 40px !important;
        }
    }
    .container-holder {
        margin-top: -80px;
    }
}

// booking-section
.booking-section {
    margin: 6vh 0;
}

// accommodation_detail
&.accommodation_detail {
    .lead-section {
        .container-holder {
            max-width: none;
        }
        .info-nav {
			@include media-breakpoint-up(md) {
            	margin-bottom: 0;
			}
        }
    }
    .content-section {
        position: relative;
        margin: 0;
        padding: 6vh 0 8vh 0;
        @extend .bg-blue-dark;
        &::after {
            content: "";
            z-index: 100;
            position: absolute;
            right: 0;
            bottom: 15px;
            width: 82px;
            height: 150px;
            background: url("/images/visual-curl-half-right-white.svg");
        }
    }
}

// landing_page
&.landing_page {
    section:not(.bg-dark) {
        .contentblock {
            .card-btn {
                @extend .btn-primary;
            }
        }
    }
}
