// colors
$blue: #5cb1da;
$blue-dark: #01759b;

$green: #a5d54c;
$green-dark: #78a81f;

$red: #d10a11;
$red-dark: #800f00;

$white: #ffffff;
$gray: #2b3437;
$black: #000000;

// theme-colors
$primary: $blue;
$primary-dark: $blue-dark;
$secondary: $green;
$secondary-dark: $green-dark;
$tertiary: $red;
$tertiary-dark: $red-dark;

$dark: $blue-dark;

// body
$body-bg: $white;
$body-color: $gray;
$body-color-dark: $white;

$link-color: $primary;
$link-decoration: underline;

// fonts
@import url("https://fonts.googleapis.com/css2?family=Montagu+Slab:wght@300;400;700&family=Proza+Libre:wght@400;700&display=swap");
$font-proza-libre: "Proza Libre", sans-serif;
$font-montagu-slab: "Montagu Slab", serif;
$font-awesome: "Font Awesome 5 Pro";

$font-family-primary: $font-proza-libre;
$font-family-secondary: $font-montagu-slab;

$font-family-base: $font-family-primary;

// typography
$font-size-base: 0.875rem; // 14px
$font-size-lg: $font-size-base * 1.14285714286; // 16px
$line-height-base: 2;

$h1-font-size: $font-size-lg * 3; // 48px
$h2-font-size: $font-size-lg * 2.25; // 36px
$h3-font-size: $font-size-lg * 1.875; // 30px
$h4-font-size: $font-size-lg * 1.5; // 24px
$h5-font-size: $font-size-lg * 1.125; // 18px
$h6-font-size: $font-size-lg; // 16px

$h1-font-size-mobile: $font-size-lg * 2.25; // 36px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 300;

// cards
$card-bg-dark: transparent;
$card-bg-overlay: rgba($black, 0.25);
$btn-border-width: 2px;
