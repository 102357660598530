// btn
.btn {
	min-width: 190px;
	padding: 6px 20px;
	border-radius: 18px;
	font-size: 15px;
	font-weight: 700;
	line-height: 20px;

	// btn-primary
	&.btn-primary {
		border-color: $primary-dark;
		background: $primary-dark;
		color: $white;
		&:hover {
			border-color: darken($primary-dark, 15%);
			background: darken($primary-dark, 15%);
			color: $white;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		border-color: $primary-dark;
		background: transparent;
		color: $primary-dark;
		&:hover {
			border-color: $primary-dark;
			background: $primary-dark;
			color: $white;
		}
	}

	// btn-secondary
	&.btn-secondary {
		border-color: $secondary-dark;
		background: $secondary-dark;
		color: $white;
		&:hover {
			border-color: darken($secondary-dark, 15%);
			background: darken($secondary-dark, 15%);
			color: $white;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		border-color: $secondary-dark;
		background: transparent;
		color: $secondary-dark;
		&:hover {
			border-color: $secondary-dark;
			background: $secondary-dark;
			color: $white;
		}
	}
	&.btn-yellow { 
		border-color: #FDC022;
		background: #FDC022;
		color: $white;
		&:hover {
			border-color: darken(#FDC022, 15%);
			background: darken(#FDC022, 15%);
			color: $white;
		}
	}

}

// btn-back
.btn-back {
	display: inline-block;
	color: $tertiary-dark;
	font-size: 15px;
	font-weight: 700;
	text-decoration: none;
	&::before {
		display: inline-block;
		content: "\f104";
		margin-right: 5px;
	}
	&:hover {
		color: $tertiary;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	background: $white;
	border-color: $primary-dark;
	color: $primary-dark;
	&:hover {
		background: $primary-dark;
		border-color: $primary-dark;
		color: $white;
	}
	&::after {
		display: inline-block;
		content: "\f107";
		margin-left: 5px;
		font-family: $font-awesome;
	}
}
