// container padding fix
.container {
	@include media-breakpoint-up(sm) {
		padding: $container-padding_sm !important;
	}
}

// container
.container-fluid {
	@include media-breakpoint-up(xl) {
		padding: 0 40px;
	}
	.grid.page_block {
		@include media-breakpoint-up(xl) {
			margin: 0 -20px -40px -20px;
		}
		.item {
			@include media-breakpoint-up(xl) {
				margin-bottom: 40px;
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
}
