// gallery
.gallery {
	.gallery-caption,
	.gallery-img {
		border-radius: 5px;
	}
	.large-item {
		margin-top: 0;
		.gallery-img {
			border-radius: 10px;
		}
		&::before {
			content: "";
			z-index: 100;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 60px;
			background: linear-gradient(180deg, rgba($primary-dark, 0) 0%, rgba($primary-dark, 1) 100%);
		}
	}
}
