// card
.card {
	background: transparent;
	border: none;
	border-radius: 0;
	box-shadow: none;
	.card-image {
		position: relative;
		border-radius: 8px;
		overflow: hidden;
		.card-image-link {
			@extend .d-block;
			.card-img {
				transition: 0.5s ease-in-out;
			}
			&:hover {
				.card-img {
					transform: scale(1.03);
				}
			}
			.card-image-caption {
				z-index: 100;
				top: 0;
				bottom: auto;
				.card-image-label {
					background-color: rgba($tertiary, 0.5);
					font-weight: 700;
				}
			}
		}
		&::before {
			content: "";
			z-index: 100;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 80px;
			background: linear-gradient(180deg, rgba($primary, 0) 0%, rgba($primary, 1) 100%);
		}
	}
	.card-body {
		@include media-breakpoint-down(xs) {
			padding: 15px 0 0 0;
		}
		padding: 15px 15px 0 15px;
		background: transparent;
		.card-caption {
			text-align: unset;
			.card-title-link {
				text-align: unset;
				.card-title {
					font-size: $h3-font-size;
					transition: none;
					@include media-breakpoint-up(md) {
						font-size: $h2-font-size;
					}
					text-align: unset;
				}
				&:hover {
					color: $primary-dark;
				}
			}
			.card-subtitle {
				font-size: $h5-font-size;
				text-align: unset;
			}
			.card-description {
				min-height: 0;
				max-height: none;
				text-align: unset;
				&::after {
					@extend .d-none;
				}
			}
		}
		.card-buttons {
			.card-btn {
				background: transparent;
				border-color: $white;
				color: $white;
				&:hover {
					background: darken($primary-dark, 15%);
					border-color: $white;
					color: $white;
				}
			}
		}
	}
}
