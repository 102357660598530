// eyecatcher
.eyecatcher {
	background-color: rgba($primary, 0.1);
	height: 50vh;
	min-height: 350px;
	max-height: 540px;
	.owl-carousel {
		min-height: unset;
		.item {
			height: 50vh !important;
			min-height: 350px;
			max-height: 540px;
		}
	}
	&.large {
		height: 100vh;
		min-height: 400px;
		max-height: 1080px;
		.owl-carousel {
			min-height: unset;
			.item {
				height: 100vh !important;
				min-height: 400px;
				max-height: 1080px;
			}
		}
	}
	.container-fluid {
		max-width: none;
	}
	.owl-carousel {
		.item {
			padding: 0 0 200px 0;
			align-items: flex-end;
			.owl-caption {
				.owl-title {
					margin-bottom: 0;
					font-size: 40px;
					font-weight: 400;
					text-shadow: none;
					font-family: $font-family-secondary;
					@include media-breakpoint-up(xl) {
						font-size: 48px;
					}
				}
				.owl-btn {
					margin-top: 30px;
				}
			}
		}
		.owl-dots,
		.owl-nav {
			@extend .d-none;
		}
	}
	&::after {
		content: "";
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 300px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	}
}
&.home {
	.eyecatcher {
		&::after {
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 1) 55px,
				rgba(255, 255, 255, 0) 100%
			);
		}
	}
}
