/* ul */
ul.custom-list {
    @extend .list-unstyled;
    li {
        position: relative;
        padding: 0 0 0 25px;
        &::before {
            content: '\f00c';
            position: absolute;
            top: 0;
            left: 0;
            font-family: 'Font Awesome 5 Pro';
            color: $primary;
            font-weight: 900;
        }
    }
}

/* ol */
ol.custom-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    margin: 0;
    @extend .list-unstyled;
    li {
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        min-height: 60px;
        margin: 10px 1%;
        padding-left: 25px;
        font-size: 13px;
        font-weight: 700;
        line-height: 1.2;
        @include media-breakpoint-up(md) {
            font-size: 15px;
        }
        &::before {
            content: '';
            z-index: -1;
            position: absolute;
            margin-left: -25px;
            width: 50px;
            height: 60px;
            background: url('/images/visual-splash-blue.svg');
        }
        a {
            color: $white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
