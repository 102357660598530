&.home .header {
	position: fixed;
}
.header {
    z-index: 998;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    transition: $transition-base;

    // header-top
    .header-top {
        @include media-breakpoint-down(sm) {
            display: none;
        }
        .container-fluid {
            @include media-breakpoint-up(xl) {
                padding: 0 40px !important;
            }
        }
        .navbar {
            padding: 15px 0;
            justify-content: flex-end;
            transition: padding 0.5s;

            // header-contact
            .header-contact {
                align-items: center;
                margin-bottom: 0;
                li {
                    margin-right: 20px;
                    font-size: 13px;
                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        i {
                            margin-right: 5px;
                            font-size: 18px;
                        }
                        &:hover {
                            color: $tertiary-dark;
                        }
                    }
                    &:nth-child(n + 3) {
                        margin-right: 15px;
                        i {
                            margin-right: 0;
                        }
                        span {
                            display: none;
                        }
                    }
                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    // header-main
    .header-main {
        background-color: transparent;
        .container-fluid {
            padding: 0 15px !important;
            @include media-breakpoint-up(xl) {
                padding: 0 40px !important;
            }
        }
        .navbar {
            padding: 15px 0;
            align-items: center;
            justify-content: flex-end !important;
            transition: padding 0.5s;

            // logo
            .logo {
                @include media-breakpoint-down(sm) {
                    position: static;
                    order: 4;
                    width: 100%;
                    a {
                        max-width: 320px;
                        margin: 0 auto;
                    }
                }
                position: absolute;
                top: -30px;
                left: 0;
                width: 320px;
                transition: 0.5s;
                @include media-breakpoint-up(xl) {
                    width: 400px;
                }
                max-width: none;
                a {
                    @extend .d-block;
                    img {
                        @extend .w-100;
                    }
                }
            }

            // navbar-toggler
            .navbar-toggler {
                z-index: 998;
                position: relative;
                display: flex !important;
                align-items: center;
                justify-content: center;
                @extend .btn;
                @extend .btn-primary;
                min-width: auto !important;
                box-shadow: 0 3px 6px rgba($black, 0.15);
                text-align: center;
                @include media-breakpoint-down(md) {
                    width: 30px;
                    height: 30px;
                    padding: 0 !important;
                }
                &[aria-expanded="true"] {
                    .fa-bars {
                        &::before {
                            content: "\f00d";
                        }
                    }
                }
                .navbar-toggler-icon {
                    width: auto;
                    height: 18px;
                    font-size: 18px;
                }
                .navbar-toggler-label {
                    margin-left: 10px;
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }

            // header-buttons
            .header-buttons {
                z-index: 998;
                position: relative;
                margin: 0 0 0 10px;
                li {
                    margin-right: 0;
                    a {
                        @extend .btn;
                        min-width: auto !important;
                        background: $tertiary-dark;
                        box-shadow: 0 3px 6px rgba($black, 0.15);
                        color: $white;
                        text-align: center;
                        &:hover {
                            background: $tertiary;
                            color: $white;
                        }
                        @include media-breakpoint-down(md) {
                            width: 30px;
                            height: 30px;
                            padding: 0 !important;
                            line-height: 26px !important;
                            span {
                                display: none;
                            }
                        }
                        @include media-breakpoint-up(lg) {
                            i {
                                display: none;
                            }
                        }
                    }
                }
            }

            // header-icons
            .header-icons {
                z-index: 998;
                position: relative;
                margin: 0 0 0 10px;
                li {
                    margin-right: 0;
                    a {
                        display: block;
                        width: 36px;
                        height: 36px;
                        border-radius: 100%;
                        background-color: $white;
                        box-shadow: 0 3px 6px rgba($black, 0.15);
                        color: $primary-dark;
                        font-size: 18px;
                        line-height: 36px;
                        text-align: center;
                        &:hover {
                            background: $primary;
                            color: $white;
                        }
                        span {
                            display: none;
                        }
                        @include media-breakpoint-down(md) {
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                        }
                    }
                }
            }

            // language
            .language {
                z-index: 998;
                position: relative;
                margin: 0 0 0 10px;
                flex-direction: row;
                .nav-item {
                    + li {
                        margin-left: 5px;
                    }
                    .nav-link {
                        display: flex;
                        padding: 0;
                        border-radius: 100%;
                        width: 36px;
                        height: 36px;
                        border-radius: 100%;
                        background-color: $white;
                        box-shadow: 0 3px 6px rgba($black, 0.15);
                        img {
                            display: block;
                            width: 20px;
                            height: 20px;
                            margin: auto;
                            border-radius: 100%;
                        }
                        &:hover {
                            background-color: $primary;
                        }
                        @include media-breakpoint-down(md) {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    &.active {
                        .nav-link {
                            background-color: $white;
                        }
                    }
                }
            }
        }
    }

    &.sticky {
        background: $white;
        box-shadow: 0 3px 6px rgba($black, 0.15);
        .header-top {
            .navbar {
                @include media-breakpoint-up(md) {
                    padding: 5px 0;
                }
            }
        }
        .header-main {
            .navbar {
                @include media-breakpoint-up(md) {
                    padding: 0 0 10px 0;
                }
                .logo {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                    @include media-breakpoint-up(md) {
                        width: 200px;
                    }
                }
            }
        }
    }
}

// header
&.accommodation_book,
&.accommodation_search_book {
	.header {
		position: relative !important;
	}
}

// navbar-toggler
&.playground {
        .navbar-toggler {
        @extend .btn;
        @extend .btn-yellow;
    }


    .header-buttons {
        li {
            a {
                @extend .btn;
                @extend .bg-red;
            }
        }
    }
}



&.playground {
    .header
    {
        .header-top {
            .navbar  {
                .header-contact {
                    li:nth-child(n + 2)
                    {
                        span{
                            display: none;
                        }
                    }
                }
           }
        }
    }

}
