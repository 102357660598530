// footer
.footer {
    position: relative;
    margin-top: 8vh;
    &::after {
        content: "";
        z-index: -1;
        position: absolute;
        bottom: 0;
        @include media-breakpoint-down(sm) {
            right: 15px;
            width: 155px;
            height: 100px;
        }
        @include media-breakpoint-up(md) {
            right: 15px;
            width: 232px;
            height: 150px;
        }
        @include media-breakpoint-up(xl) {
            right: 30px;
            width: 462px;
            height: 299px;
        }
        background: url("/images/footer-visual-curl-blue-dark.svg");
    }



    // outro
    .footer-outro {
        margin: 10vh 0 6vh 0;
        .container-holder {
            @extend .bg-red-dark;
            margin: 0;
            padding: 0 15px;
            border-radius: 10px;
            @include media-breakpoint-up(xl) {
                padding: 0 65px;
            }
            .column {
                @include media-breakpoint-up(md) {
                    padding-top: 40px;
                }
            }
            .column.wide {
                @include media-breakpoint-down(sm) {
                    padding-top: 30px;
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(7);
                }
            }
            .column.narrow {
                @include media-breakpoint-up(lg) {
                    @include make-col(5);
                }
            }
            .heading {
                position: absolute;
                left: 0;
                bottom: 100%;
                margin: 0 0 -21px 0;
                background: $tertiary;
                border-radius: 40px;
                padding: 3px 25px;
                color: $white;
                font-size: 18px;
                font-weight: 400;
                font-family: $font-family-secondary;
            }
        }
    }



    // sitelinks
    .footer-sitelinks {
        .column {
            &.three {
                color: $primary-dark;
            }
        }
        margin: 6vh 0;
        h4 {
            margin-bottom: 10px;
        }
        a {
            color: $primary-dark;
            font-weight: 700;
            text-decoration: none;
            &:hover {
                color: $primary;
            }
        }
        .footer_text {
            p:last-of-type {
                margin-bottom: 0;
            }
        }
        .footer_logolink {
            .list {
                .list-item {
                    .link {
                        display: block;
                        width: 42px;
                        height: 42px;
                        border-radius: 100%;
                        background-color: $primary-dark;
                        color: $white;
                        font-size: 24px;
                        line-height: 42px;
                        text-align: center;
                        i {
                            line-height: 42px;
                        }
                        &:hover {
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }

    // copyright
    .footer-copyright {
        padding-bottom: 15px;
        .logo {
            @include media-breakpoint-down(lg) {
                max-width: 200px;
            }
            @include media-breakpoint-up(lg) {
                max-width: 250px;
            }
            a {
                display: block;
            }
        }
        .footer_link {
            margin-top: 30px;
            .list {
                .list-item {
                    margin-right: 30px;
                    font-size: 13px;
                    .link {
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

&.playground {
    .footer{
        .footer-outro {
            .container-holder {
                @extend .bg-green-dark;
                .heading {
                    @extend .bg-green;
                }
            }
        }
    }
}
