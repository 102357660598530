// headings
h1 {
    font-weight: 400;
    line-height: 1.05;
    @include media-breakpoint-down(lg) {
        font-size: $h1-font-size-mobile;
    }
}
&.home h2 {
    font-size: $h1-font-size;
    line-height: 1.02;
    @include media-breakpoint-down(lg) {
        font-size: $h1-font-size-mobile;
    }
}

// img & iframe
img {
    max-width: 100%;
    height: auto;
}
iframe {
    max-width: 100%;
}

// bg-colors
.bg-blue { background: $primary !important;}
.bg-blue-dark { background: $primary-dark !important;}
.bg-green { background: $secondary !important;}
.bg-green-dark {background: $secondary-dark !important;}
.bg-red { background: $tertiary !important;}
.bg-red-dark { background: $tertiary-dark !important;}


// bg-colors-speeltuin
.bg-yellow { background:  #D89E00 !important;}

// transition
a,
.btn,
.owl-btn,
.card-btn { transition: 0.5s !important;}
