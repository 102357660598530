.bg-dark,
.bg-green,
.bg-red,
.bg-blue,
.bg-green-dark,
.bg-yellow,
.bg-red-dark,
.bg-blue-dark {
	background-color: $dark;

	&,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: $body-color-dark;
	}

	a {
		color: currentColor;
		&:hover {
			color: darken($body-color-dark, 25%);
		}
	}

	ul,
	ol {
		li {
			color: $body-color-dark;
			&::before {
				color: $body-color-dark;
			}
		}
	}

	hr {
		border-top-color: rgba($white, 0.1);
	}

	.btn-primary {
		border-color: $white;
		background: $white;
		color: $primary-dark;
		&:hover {
			border-color: darken($primary-dark, 15%);
			background: darken($primary-dark, 15%);
			color: $white;
		}
	}

	.btn-outline-primary {
		border-color: $white;
		background: transparent;
		color: $white;
		&:hover {
			border-color: $white;
			background: darken($primary-dark, 15%);
			color: $white;
		}
	}
	.btn-secondary {
		border-color: $white;
		background: $white;
		color: $secondary-dark;
		&:hover {
			border-color: darken($secondary-dark, 15%);
			background: darken($secondary-dark, 15%);
			color: $white;
		}
	}
	.btn-outline-secondary {
		border-color: $white;
		background: transparent;
		color: $white;
		&:hover {
			border-color: $white;
			background: darken($secondary-dark, 15%);
			color: $white;
		}
	}

	.alert-danger {
		background: transparent;
	}

	.card {
		background: $card-bg-dark;
		border: $card-border-width solid transparent;

		.card-image {
			background: rgba($black, 0.1);
		}

		.card-body {
			background: $card-bg-dark;
		}

		.card-caption {
			.card-title {
				color: $body-color-dark;
			}

			.card-title-link {
				color: $body-color-dark;

				.card-title {
					margin: 0;
					color: inherit;
				}

				&:hover {
					color: $body-color;
				}
			}

			.card-subtitle {
				color: $body-color-dark;
			}

			.card-description {
				color: $body-color-dark;
			}

			.card-prices,
			.card-price {
				color: $body-color-dark;
			}
		}

		.card-info {
			.card-prices {
				.card-price-label,
				.card-old-price {
					color: $body-color-dark;
				}

				.card-price {
					color: $primary;
				}
			}

			.card-traveldata {
				.card-arrival-label,
				.card-arrival-data,
				.card-duration-data {
					color: $body-color-dark;
				}
			}
		}

		.card-btn {
			background: $body-color-dark;
			border: $btn-border-width solid $body-color-dark;

			color: $body-color;

			&:hover {
				background: $primary;
				border: $btn-border-width solid $primary;

				color: $body-color-dark;
			}
		}

		.form {
			.form-control-label {
				color: $body-color-dark;
			}
			.reCAPTCHA-text {
				color: $body-color-dark;
			}
		}

		.owl-carousel {
			&.slider {
				.owl-nav {
					.owl-prev,
					.owl-next {
						color: $body-color-dark;

						&:hover {
							color: $primary;
						}
					}
				}

				.owl-dots {
					.owl-dot {
						span {
							border: 2px solid $primary;
						}

						&:hover {
							span {
								background: $body-color-dark;
								border: 2px solid $body-color-dark;
							}
						}
						&.active {
							span {
								background: $primary;
								border: 2px solid $primary;
							}
						}
					}
				}
			}
		}
	}
}
