// mini-sab
.mini-sab {
	z-index: 100;
	position: relative;
	.container {
		@extend .d-flex;
		@extend .justify-content-center;
		.container-holder {
			position: relative;
			min-width: 300px;
			border-radius: 40px;
			background: $primary;
			color: $white;
			@include media-breakpoint-down(md) {
				margin-top: -20px;
				padding: 20px;
			}
			@include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
				margin-bottom: 20px;
				padding: 10px 45px;
			}
			@include media-breakpoint-up(xl) {
				padding: 10px 60px;
			}

			/* heading */
			.heading {
				position: absolute;
				left: 30px;
				bottom: 100%;
				margin-bottom: -10px;
				background: $primary-dark;
				border-radius: 40px;
				padding: 6px 20px;
				color: $white;
				font-size: 18px;
				font-weight: 400;
				font-family: $font-family-secondary;
			}

			/* mini_search_book */
			.mini_search_book {
				@include media-breakpoint-down(sm) {
					display: none !important;
				}
			}

			/* book-button */
			.book-button {
				@include media-breakpoint-up(md) {
					display: none !important;
				}
				margin: 0;
				justify-content: center;
				li {
					margin-right: 0;
					a {
						@extend .btn;
						padding: 10px !important;
						border-radius: 22px !important;
						background: $tertiary-dark;
						box-shadow: 0 3px 6px rgba($black, 0.15);
						color: $white;
						text-align: center;
						&:hover {
							background: $tertiary;
							color: $white;
						}
						i {
							display: none;
						}
					}
				}
			}
		}
	}
}

// home
&.home {
	.mini-sab {
		.container {
			.container-holder {
				@include media-breakpoint-down(md) {
					margin-top: -120px;
				}
				@include media-breakpoint-up(lg) {
					margin-bottom: 45px;
				}
				.book-button {
					display: none !important;
				}
				.mini_search_book {
					display: block !important;
				}
			}
		}
	}
}


&.playground {
	.header{

		.header-icons {
			li {
				&:first-of-type{
					margin-right: 6px; 
				}
			}
		}
	}

	.mini-sab { 
		.container {
			.container-holder {


				.heading {
					background: $red;   
					left: 44px;
				}  
				
				background: $yellow; 
				padding: 20px !important;

				.book-button{
					display: flex !important; 
				}

				.book-button {
					li {
						margin: 0 10px 0 10px !important; 
						@include media-breakpoint-down(md){
							margin-bottom:15px !important;
							width: 100% !important;
							max-width: 139px !important;
						}
						a {
							background:$blue-dark; 
							min-width: 146px !important;

							@include media-breakpoint-down(md){
								margin-bottom:15px !important;
								width: 100% !important; 
								min-width: 1px !important;
							}
						}
					}
				}
			} 
		}
	}
}
